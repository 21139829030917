import React from 'react'
import { useHistory } from 'react-router-dom'

import MainStore from '../stores/main.store'

export default function LogoutPage() {
  const history = useHistory()

  React.useEffect(() => {
    MainStore.clear()
    history.push('/login')
  }, [])

  return <div className="page page-logout">logging out</div>
}
