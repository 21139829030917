/**
 * Login
 * @param {object} api API Instance
 * @param {object} options Options
 * @return {object} Result Object
 */
export const login = function(api, options = {}) {

  const url = api.baseURL + '/login'

  const requestOptions = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options),
  }

  return api.request(url, requestOptions)
}
