import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import MainStore from '../stores/main.store'

export default function ProtectedRoute({ children, ...props }) {
  return (
    <Route
      {...props}
      render={({ location }) =>
        MainStore.isLoggedIn ? (
          children
        ) : (
          <Redirect to={{ pathname: '/login' }} state={{ from: location }} />
        )
      }
    />
  )
}
