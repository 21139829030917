import MainStore from '../stores/main.store'

/**
 * Get Truck By Plate
 * @param {object} api API Instance
 * @param {object} options Options
 * @return {object} Result Object
 */
export const truckGetByPlate = function (api, options = {}) {
  const url = api.baseURL + '/get-truck-by-plate'

  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + MainStore.user.token,
    },
    body: JSON.stringify(options),
  }

  return api.request(url, requestOptions)
}
