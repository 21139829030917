import React from 'react'
import { observer } from 'mobx-react-lite'
import { Link, Redirect } from 'react-router-dom'
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material'

import Message from '../components/message'
import MainStore from '../stores/main.store'
import API from '../api'

function ResultPage() {
  const [skipPlan, setSkipPlan] = React.useState(false)
  const [attachImage, setAttachImage] = React.useState(null)
  const [status, setStatus] = React.useState('idle')
  const [error, setError] = React.useState(null)

  const [form, setForm] = React.useState({
    app_used: 'koda_app_parceline_pwa',
    scancode: MainStore.scancode,
    serviceid: 'koda_app',
    capacity_remaining: '',
    route: '',
    userid: MainStore.user.userid,
    deviceid: 'webapp-pwa',
    capture_type: 'phone_camera',
    scanned_at_utc: new Date().toISOString(),
    gps_location: null,
    truck_plate: MainStore.truckPlate,
    plan: null,
  })

  if (MainStore.scancode === null) {
    return <Redirect to="/" />
  }

  const onFormChanged = (key, val) => setForm((old) => ({ ...old, [key]: val }))

  const onSubmit = () => {
    setStatus('submitting')

    API.availableCapacity(form)
      .then((response) => {
        if (!response.success) {
          const errorMessage =
            response.error || response.message || 'Please try again'
          setError(errorMessage)
          return
        }
        setStatus('success')
      })
      .catch((error) => {
        setError('Unknown Error')
        setStatus('error')
      })
  }

  const isButtonDisabled =
    status === 'submitting' 
    || form.route === '' 
    || form.capacity_remaining === '' 
    || (!skipPlan && !attachImage)

  const onImageChanged = (event) => {
    const image = event.target.files[0]
    if (!image) {
      return
    }

    const fileReader = new FileReader()
    fileReader.readAsDataURL(image)
    fileReader.onload = () => {
      const imageCode = fileReader.result
      setAttachImage(imageCode)
      setForm((old) => ({ ...old, plan: imageCode }))
    }
  }

  const renderImageUpload = () => {
    if (skipPlan) {
      return null
    }

    if (attachImage) {
      return (
        <Box sx={{ margin: '10px 0' }}>
          <Button variant="outlined" onClick={() => setAttachImage(null)}>
            Remove Image
          </Button>
        </Box>
      )
    }

    return (
      <Box>
        <label htmlFor="contained-button-file">
          <input
            style={{ display: 'none' }}
            id="contained-button-file"
            type="file"
            accept="image/*"
            capture
            onChange={onImageChanged}
          />
          <Button
            variant="contained"
            component="span"
            fullWidth
            sx={{ marginTop: '10px' }}
          >
            ATTACH IMAGE
          </Button>
        </label>
      </Box>
    )
  }

  return (
    <div className="page page-result">
      <Message
        open={error || status === 'success'}
        onClose={() => setError(null)}
        message={error ? error : 'Submit Successfully'}
      />
      <div className="page-header">Scanning successful</div>
      <div className="page-content">
        <div className="message">
          Thank you. You are currently driving a truck with the number plate:
        </div>
        <h1 className="plate-number">{MainStore.truckPlate}</h1>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select your run</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select your run"
            value={form.route}
            onChange={(event) => onFormChanged('route', event.target.value)}
          >
            {MainStore.journeys.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {renderImageUpload()}
        <FormControlLabel
          label="No Load Plan"
          control={
            <Checkbox
              checked={skipPlan}
              onChange={(event) => setSkipPlan(event.target.checked)}
            />
          }
        />
        <div className="tcp-form">
          <h4>Please enter free TCP spaces below</h4>
          <TextField
            sx={{ background: '#fff' }}
            id="outlined-basic"
            variant="outlined"
            value={form.capacity_remaining}
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={(event) =>
              onFormChanged('capacity_remaining', event.target.value)
            }
          />
        </div>
        <div className="action-buttons">
          <Button variant="contained" size="large">
            <Link to="/scan">SCAN AGAIN</Link>
          </Button>
          {status !== 'success' && (
            <Button
              variant="contained"
              className="button-purple"
              size="large"
              onClick={onSubmit}
              disabled={isButtonDisabled}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(ResultPage)
