import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import LogoutIcon from '@mui/icons-material/Logout'

import logo from '../images/logo.png'

export default function TopBar() {
  const history = useHistory()
  const [showMenu, toggleMenu] = React.useState(false)

  const onMenuClick = (path) => {
    history.push(path)
    toggleMenu(false)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ background: '#fff' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => toggleMenu(!showMenu)}
          >
            <MenuIcon sx={{ color: 'blue' }} />
          </IconButton>
          <Box component="img" src={logo} sx={{ width: '150px' }} />
        </Toolbar>
      </AppBar>
      <Drawer open={showMenu} onClose={() => toggleMenu(false)}>
        <List>
          <ListItem button key="scan" onClick={() => onMenuClick('/')}>
            <ListItemIcon>
              <QrCodeScannerIcon />
            </ListItemIcon>
            <ListItemText>Scan new code</ListItemText>
          </ListItem>
          <ListItem button key="logout" onClick={() => onMenuClick('/logout')}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  )
}
