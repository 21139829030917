import React from 'react'
import { Link } from 'react-router-dom'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { QrReader } from 'react-qr-reader'
import { Button } from '@mui/material'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'

import Message from '../components/message'
import { ViewFinder } from '../components/icons/view.finder'
import MainStore from '../stores/main.store'
import API from '../api'

const styles = {
  container: {
    width: '100%',
    margin: 'auto',
  },
}

const getKeysFromBarcode = (barcode) => {
  const keys = barcode
    .split('?')
    .filter((section) => section)
    .reduce((accumulator, param) => {
      const [key, value] = param.split('=')
      accumulator[key] = value
      return accumulator
    }, {})
  return keys
}

function ScanPage() {
  const [status, setStatus] = React.useState('idle') // idle | scanning
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    runInAction(() => {
      MainStore.scancode = null
      MainStore.truckPlate = null
      MainStore.journeys = []
    })
  }, [])

  const onScan = (val) => {
    if (MainStore.scancode || status === 'scanning') {
      return
    }

    const scanCode = val?.text

    if (!scanCode) {
      return
    }

    setStatus('scanning')

    const barcodeKeys = getKeysFromBarcode(scanCode)
    const plate = barcodeKeys.Truck

    if (!plate) {
      setError('Invalid QR Code')
      setStatus('idle')
      return
    }

    // Get truck details
    return API.truckGetByPlate({ plate })
      .then((truckDetails) => {
        if (truckDetails.status !== 'success') {
          setError('Could not load truck details')
          return
        }

        const data = { ...truckDetails.data }
        let journeys = data.journeys || []

        journeys = journeys.map((journey) => {
          const { id, name } = journey
          return { value: id, label: name }
        })

        runInAction(() => {
          MainStore.journeys = journeys
          MainStore.scancode = scanCode
          MainStore.truckPlate = plate
        })
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setStatus('idle')
      })
  }

  const options = {
    ViewFinder,
    videoId: 'video',
    scanDelay: 500,
    constraints: {
      facingMode: 'environment',
    },
  }

  return (
    <div className="page page-scan">
      <Message
        open={error !== null}
        message={error}
        type="error"
        onClose={() => setError(null)}
      />
      <div className="page-header">
        <QrCodeScannerIcon />
        Scanning QR Code
      </div>
      <div style={styles.container} className="scanner-container">
        <QrReader onResult={onScan} {...options} />
      </div>
      {MainStore.scancode ? (
        <div className="action-buttons">
          <Button variant="contained" size="large">
            <Link to="/scan">RE-SCAN</Link>
          </Button>
          <Button className="button-purple" variant="contained" size="large">
            <Link to="/result">{MainStore.truckPlate}</Link>
          </Button>
        </div>
      ) : (
        <div className="message">Hold your phone and scan truck code</div>
      )}
    </div>
  )
}

export default observer(ScanPage)
