const prefix = 'parceline-webapp-'
const storage = {
  get: (key) => {
    const item = window.localStorage.getItem(prefix + key)

    return item ? JSON.parse(item) : null
  },
  set: (key, val) => {
    window.localStorage.setItem(prefix + key, JSON.stringify(val))

    return true
  },
  remove: (key) => {
    window.localStorage.removeItem(key)
    return true
  },
  clear: () => {
    window.localStorage.clear()
    return true
  },
}

export default storage
