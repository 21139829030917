import config from '../config/config'

import { availableCapacity } from './available.capacity'
import { login } from './login'
import { logout } from './logout'
import { truckGetByPlate } from './truck.get.by.plate'
import { validate } from './validate'

const api = {
  baseURL: config.baseApiUrl,

  request: (url, requestOptions) => {
    return fetch(url, requestOptions)
      .then((result) => result.json())
      .catch((rejection) => ({ error: rejection }))
  },
}

export default {
  availableCapacity: (options) => availableCapacity(api, options),
  login: (options) => login(api, options),
  logout: (options) => logout(api, options),
  validate: (options) => validate(api, options),
  truckGetByPlate: (options) => truckGetByPlate(api, options),
}
