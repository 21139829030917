import React from 'react'

export default class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null,
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(...args) {
    console.error(...args)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <h1>An error occured. Please reload.</h1>
        </div>
      )
    }

    return this.props.children
  }
}
