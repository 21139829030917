import { makeAutoObservable } from 'mobx'
import storage from '../utils/storage'

class MainStore {
  _user = {}
  truckPlate = null
  scancode = null
  journeys = []

  constructor() {
    makeAutoObservable(this)
  }

  get user() {
    return this._user || {}
  }

  /**
   * Is Logged In
   * @return {boolean} True When Logged In
   */
  get isLoggedIn() {
    return this.user.userid && this.user.token
  }

  loadSession(session) {
    storage.set('session', session)
    this._user = {
      userid: session.userid,
      token: session.token,
    }
  }

  clear() {
    this._user = {}
    this.scancode = null
    this.truckPlate = null
    this.journeys = []
    storage.clear()
  }
}

export default new MainStore()
