import React from 'react'
import { action } from 'mobx'
import { useHistory } from 'react-router-dom'
import { Box, TextField, Container } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import Message from '../components/message'
import API from '../api'
import MainStore from '../stores/main.store'
import logo from '../images/logo.png'

export default function LoginPage() {
  const history = useHistory()
  const [showAlert, setAlert] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [form, setForm] = React.useState({
    mobile_number: '',
    password: '',
  })

  const onSubmit = () => {
    setLoading(true)
    API.login(form)
      .then(
        action((loginResult) => {
          if (!loginResult.success) {
            setAlert('error')
            return
          }

          // Save user details to MainStore
          setAlert('success')
          MainStore.loadSession(loginResult)
          setTimeout(() => history.push('/'), 2100)
        })
      )
      .catch(() => setAlert('error'))
      .finally(() => setLoading(false))
  }

  const onFormChanged = (event, key) => {
    setForm((old) => ({ ...old, [key]: event.target.value }))
  }

  return (
    <Container
      className="page page-login"
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Message
        open={Boolean(showAlert)}
        onClose={() => setAlert}
        type={showAlert}
        message={
          showAlert === 'success'
            ? 'Login successfully.'
            : 'Server Error: Failed to login'
        }
      />
      <Box component="img" src={logo} sx={{ marginBottom: 5 }} />
      <Box component="form">
        <TextField
          fullWidth
          label="login"
          defaultValue={form.mobile_number}
          sx={{ marginBottom: '10px' }}
          onChange={(event) => onFormChanged(event, 'mobile_number')}
        />
        <TextField
          fullWidth
          defaultValue={form.password}
          label="password"
          onChange={(event) => onFormChanged(event, 'password')}
        />
        <LoadingButton
          loading={loading}
          sx={{ width: '100%' }}
          variant="contained"
          style={{ marginTop: 40 }}
          size="large"
          onClick={onSubmit}
        >
          Sign In
        </LoadingButton>
      </Box>
    </Container>
  )
}
