import React from 'react'
import { Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'

const Alert = React.forwardRef(function (props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function Message({
  open = false,
  onClose = () => {},
  message = '',
  type = 'success',
  autoHideDuration = 2000,
}) {
  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
      <div style={{ width: '100%' }}>
        <Alert severity={type}>{message}</Alert>
      </div>
    </Snackbar>
  )
}
