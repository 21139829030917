import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import LogoutPage from './logout'
import ResultPage from './result'
import ScanPage from './scan'

import ProtectedRoute from '../components/protected.route'
import TopBar from '../components/appbar'

export default function HomePage() {
  return (
    <div className="home">
      <TopBar />
      <Switch>
        <ProtectedRoute path="/logout">
          <LogoutPage />
        </ProtectedRoute>
        <ProtectedRoute path="/result">
          <ResultPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/">
          <ScanPage />
        </ProtectedRoute>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </div>
  )
}
