import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { CircularProgress, CssBaseline } from '@mui/material'
import addToHomeScreen from 'a2hs.js'

import LoginPage from './pages/login'
import HomePage from './pages/home'

import MainStore from './stores/main.store'
import storage from './utils/storage'
import ProtectedRoute from './components/protected.route'
import { shareSvg } from './components/icons/share.button'

import './app.scss'

function App() {
  const [ready, setReady] = React.useState(false)

  React.useEffect(() => {
    const session = storage.get('session')
    if (session) {
      MainStore.loadSession(session)
    }
    setReady(true)

    addToHomeScreen({
      htmlContent: `Install <b>Parceline Utilisation App</b> Tap ${shareSvg} and <b>Add to Home Screen</b>`,
    })
  }, [])

  return (
    <div className="App">
      <CssBaseline />
      <BrowserRouter>
        {ready ? (
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <ProtectedRoute path="/">
              <HomePage />
            </ProtectedRoute>
          </Switch>
        ) : (
          <CircularProgress
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              margin: '-20px -20px',
            }}
          />
        )}
      </BrowserRouter>
    </div>
  )
}

export default App
